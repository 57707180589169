import React, { useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { PageLayout } from "./components/PageLayout";
import { ProfileData } from "./components/ProfileData";
import { callMsGraph } from "./graph";
import Button from "react-bootstrap/Button";
import "./styles/App.css";
import PeakwardApps from "./components/PeakwardApps";
import logo from "./assets/image/peakward_logo.png";
import { SignOutButton } from "./components/SignOutButton";
import { useEffect } from "react";

/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
const ProfileContent = () => {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);

    function RequestProfileData() {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            callMsGraph(response.accessToken).then(response => setGraphData(response));
        });
    }

    useEffect(() => {
        RequestProfileData()
    }, []);

    return (
        <>

                <>
                <div className="max-w-7xl mx-auto px-5 lg:px-0">
                    <div class="max-w-7xl mx-auto px-10 lg:px-0 flex justify-between py-3 items-center">
                    <div>
                        <a href="/">
                            <img src={logo} className="w-40"/>
                        </a>
                    </div>
                        <div>
                            <span className="card-title text-lg">Welcome <span className="text-xl font-bold">{accounts[0].name}</span></span><br/>
                            <span className="card-title text-lg"> {graphData?.userPrincipalName}</span><br/>
                            <SignOutButton/>
                        </div>
                        
                    </div>
                    
                    <div className="w-full p-2 bg-white border rounded-lg shadow-md sm:p-5 dark:bg-gray-800 dark:border-gray-700 mt-5">
                        <div className="flex items-center justify-between mb-4">
                            <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">Announcements</h5>
                            
                    </div>
                    <div className="flow-root">
                            <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
                                <li className="py-3 sm:py-4">
                                    <div className="flex items-center space-x-4">
                                        <div className="flex-shrink-0">
                                            {/* <img className="w-8 h-8 rounded-full" src="/docs/images/people/profile-picture-1.jpg" alt="Neil image"/> */}
                                        </div>
                                        <div className="flex-1 min-w-0">
                                            <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                            Opening dalam teks ini terdiri dari judul “announcement,” serta “In commoration of the 74th Indonesian Independence Dat, the management will hold an inter-division competition which will be held on Friday, August 16, 2019
                                            </p>
                                            <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                                From CEO
                                            </p>
                                        </div>
                                        
                                    </div>
                                </li>

                            </ul>
                    </div>
                    </div>

                </div>
                
                <PeakwardApps graphData={graphData}/>
                </>

        </>
    );
};

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {   
    
    const { instance } = useMsal();

    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
                console.log(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
    }


    return (
        <>
            <AuthenticatedTemplate>
                <ProfileContent />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
            <div className="grid place-items-center h-screen bg-[#F9FAFB" style={{backgroundColor:'#bcc3d7'}}>
                <div className="max-w-7xl mx-auto flex items-center justify-center mx-2 lg:px-0">
                    
                    <div className="bg-slate-100 shadow-md w-full border rounded-lg shadow-md sm:p-5 ">
                        <div className="mx-auto flex justify-center mb-5" style={{textAlign:'center'}}>
                            <img src={logo} className="w-40 pt-10"/>
                        </div>
                        <div className="flex items-center justify-center mb-4">
                            <h5 className="text-xl font-bold leading-none text-center text-gray-900 ">Welcome to Peakward Central Platform</h5>
                            
                        </div>
                    <div className="flow-root text-center">
                            <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
                                <li className="py-3 sm:py-4">
                                    <div className="flex items-center space-x-4">
                                        <div className="flex-shrink-0">
                                            {/* <img className="w-8 h-8 rounded-full" src="/docs/images/people/profile-picture-1.jpg" alt="Neil image"/> */}
                                        </div>
                                        <div className="flex-1 min-w-0">
                                            <p className="text-sm font-medium text-gray-900 m-2 truncate ">
                                            Please sign-in to see your profile information.
                                            </p>
                                            <button type="button" onClick={() => handleLogin("popup")} className="text-white bg-[#2F2F2F] hover:bg-[#3b5998]/90 focus:ring-4 focus:outline-none focus:ring-[#3b5998]/50 font-medium text-sm px-4 py-2.5 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 m-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="21" height="21" viewBox="0 0 21 21"><title>MS-SymbolLockup</title><rect x="1" y="1" width="9" height="9" fill="#f25022"/><rect x="1" y="11" width="9" height="9" fill="#00a4ef"/><rect x="11" y="1" width="9" height="9" fill="#7fba00"/><rect x="11" y="11" width="9" height="9" fill="#ffb900"/></svg>
                                                Sign in with Microsoft
                                            </button>
                                        </div>
                                        
                                    </div>
                                </li>

                            </ul>
                    </div>
                    </div>

                </div>
            </div>
            </UnauthenticatedTemplate>
        </>
    );
};

export default function App() {
    return (
        <PageLayout>
            <MainContent />
        </PageLayout>
    );
}
