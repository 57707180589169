import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { useState } from "react";
import businessCalendar from '../assets/image/app-icon/business-calendar.jpg';
import businessManagement from '../assets/image/app-icon/business-management.jpg';
import flowTracker from '../assets/image/app-icon/flow-tracker.jpg';
import microsoft from '../assets/image/app-icon/microsoft-365.jpg';
import peakfund from '../assets/image/app-icon/peak-fund.jpg';
import Peakwardicon from '../assets/image/app-icon/Peakward-icon.jpg';
import shipment from '../assets/image/app-icon/shipment.jpg';
import inventory from '../assets/image/app-icon/file-inventory.jpg';

const appUrls = {
    'hrms' : 'https://peakwardhrm.techsupporta.com/sso-login',
    'shipment' : 'https://peakword-webportal.techsupporta.com/sso-login',
    'flow' : 'https://lineflow.techsupporta.com/sso-login',
    'bmp' : 'https://peakward-officeexp.techsupporta.com/sso-login',
    'pickfund' : 'https://www.peakfund.net/wp-admin/admin-ajax.php'
}

export default function PeakwardApps({ graphData }) {

    const { accounts } = useMsal();
    const gotoApp = (app) => {

        openWindowWithPost(appUrls[app], {
            email: graphData.userPrincipalName,
            sso_login: 'yes',
            action: 'fundraiser_login'
        })
        
    }

    function openWindowWithPost(url, data) {
        var form = document.createElement("form");
        form.target = "_blank";
        form.method = "POST";
        form.action = url;
        form.style.display = "none";
    
        for (var key in data) {
            var input = document.createElement("input");
            input.type = "hidden";
            input.name = key;
            input.value = data[key];
            form.appendChild(input);
        }
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    }

    
    return (
        <>
        <section className="pt-12">
            <div className="grid grid-cols-12 gap-4 max-w-7xl mx-auto px-5 lg:px-0 min-h-[300px]">
                <div className="col-span-12 sm:col-span-6 md:col-span-3 duration-300 hover:scale-[1.03]">
                    <a href="https://office.live.com/start/Calendar.aspx?ui=en%2DUS&rs=US" target="_blank" className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-white-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                    <img src={businessCalendar}/>
                    </a>
                </div>
                <div className="col-span-12 sm:col-span-6 md:col-span-3 duration-300 hover:scale-[1.03]" onClick={() => gotoApp('bmp')}>
                    <a href="#" className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-white-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                    <img src={businessManagement}/>
                    </a>
                </div>
                <div className="col-span-12 sm:col-span-6 md:col-span-3 duration-300 hover:scale-[1.03]" onClick={() => gotoApp('flow')}>
                    <a href="#" className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-white-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                    <img src={flowTracker}/>
                    </a>
                </div>
                <div className="col-span-12 sm:col-span-6 md:col-span-3 duration-300 hover:scale-[1.03]" >
                    <a href="https://outlook.office365.com/mail/" target="_blank" className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-white-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                    <img src={microsoft}/>
                    </a>
                </div>
                <div className="col-span-12 sm:col-span-6 md:col-span-3 duration-300 hover:scale-[1.03]" onClick={() => gotoApp('pickfund')}>
                    <a href="#" className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-white-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                    <img src={peakfund}/>
                    </a>
                </div>
                <div className="col-span-12 sm:col-span-6 md:col-span-3 duration-300 hover:scale-[1.03]" onClick={() => gotoApp('hrms')}>
                    <a href="#" className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-white-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                    <img src={Peakwardicon}/>
                    </a>
                </div>
                <div className="col-span-12 sm:col-span-6 md:col-span-3 duration-300 hover:scale-[1.03]" onClick={() => gotoApp('shipment')}>
                    <a href="#" className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-white-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                    <img src={shipment}/>
                    </a>
                </div>
                <div className="col-span-12 sm:col-span-6 md:col-span-3 duration-300 hover:scale-[1.03]">
                    <a href="https://onedrive.live.com/" target="_blank" className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-white-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                    <img src={inventory}/>
                    </a>
                </div>
            </div>
        </section>
        </>
    );
}